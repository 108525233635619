import clsx from 'clsx';
import { useState } from 'react';
import { ColorSelectorTab } from '@/components/colorSelector/ColorSelectorTab';
import QuickShopTray from '@/components/quickShopTray/QuickShopTray';

import styles from './ColorSelector.module.scss';

export type ColorSelectorProps = {
  title: string;
  tabs: ColorSelectorTabs[];
  isLoading: boolean;
};

export type ColorSelectorTabs = {
  title: string;
  description: string;
  products: ColorSelectorProduct[];
};

export type ColorSelectorProduct = {
  handle: string;
  title: string;
  shadeColor: string;
};

/**
 * ColorSelector Component.
 */
export const ColorSelector = ({
  title,
  tabs,
  isLoading,
}: ColorSelectorProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeProductTitle, setActiveProductTitle] = useState('');

  if (!tabs.length) {
    return null;
  }

  return (
    <div className={styles.colorSelector__wrapper}>
      <section className={styles.colorSelector}>
        <div className={clsx(styles.colorSelector__header, 'container')}>
          <h2
            className={clsx('text-heading-h2-mobile text-heading-h2-desktop', {
              ['is-loading']: isLoading,
            })}
          >
            {title}
          </h2>

          <div className={styles.colorSelector__controlsWrapper}>
            <div className={styles.colorSelector__controls}>
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={clsx(styles.colorSelector__control, {
                    ['isActive']: index === activeTabIndex,
                    ['is-loading']: isLoading,
                  })}
                  onClick={() => setActiveTabIndex(index)}
                >
                  <h3
                    className={clsx(
                      'text-utility-utility-mobile',
                      'text-utility-utility-desktop'
                    )}
                  >
                    {tab.title}
                  </h3>
                </button>
              ))}
            </div>
          </div>
        </div>

        {tabs.map((tab, index) => (
          <ColorSelectorTab
            key={index}
            {...tab}
            isActive={index === activeTabIndex}
            setActiveProductTitle={setActiveProductTitle}
          />
        ))}

        {activeProductTitle && <QuickShopTray title={activeProductTitle} />}
      </section>
    </div>
  );
};
