import { useQuery } from '@tanstack/react-query';
import type { ProductCardProduct } from '@we-make-websites/ui-lib/src/components';
import { useContext } from 'use-context-selector';
import { CustomerContext } from '@/components/customerContext/CustomerContext';
import { localeToCountryLanguage } from '@/components/route/utils/localeToCountryLanguage';
import { useGetProductsQuery } from '@/lib/shopify-storefront/__generated__/ProductsQuery';
import type { GetProductsQueryVariables } from '@/lib/shopify-storefront/__generated__/ProductsQuery';
import { storefrontQueryDataSource } from '@/lib/shopify-storefront/dataSources';
import type { StoreLocale } from '@/root/constants';
import { formatProductForProductCard } from '@/utils/format/shopify/formatProduct';

/**
 * A hook for querying product's to use with Product Cards.
 * @param title - The product title to use in query.
 * @param locale - The store locale.
 * @returns {Object}
 */
export const useProductsByTitleQuery = (
  title: string,
  locale: StoreLocale
): {
  data: ProductCardProduct[] | undefined;
  isLoading: boolean;
} => {
  const localeCodes = localeToCountryLanguage(locale);
  const customerContext = useContext(CustomerContext);

  if (customerContext === undefined)
    throw new Error(
      'useProductsByTitleQuery must be used within a CustomerContext'
    );

  const { proCustomer } = customerContext;

  /**
   * If product title has ' - ' use the fist part of title in query
   */
  const [sanitizedTitle] = title.split(' - ');
  const accountType = proCustomer ? 'pro' : 'consumer';

  const productsQuery = `title:"${sanitizedTitle}" AND tag:"${accountType}"`;

  const getProductsQueryVariables: GetProductsQueryVariables = {
    productQuery: productsQuery,
    includeReferenceMetafieldDetails: true,
    ...localeCodes,
  };

  /**
   * Querying products by title.
   */
  const { data, isLoading } = useQuery(
    useGetProductsQuery.getKey(getProductsQueryVariables),
    useGetProductsQuery.fetcher(
      storefrontQueryDataSource(locale),
      getProductsQueryVariables
    ),
    {
      select: (rawData) => {
        const formattedProducts: ProductCardProduct[] = [];
        rawData.products.nodes.forEach((product) => {
          const formattedProduct = formatProductForProductCard({ product });
          if (!formattedProduct) return;

          formattedProducts.push(formattedProduct);
        });

        return formattedProducts;
      },
    }
  );

  return { data, isLoading };
};
